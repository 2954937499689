import React from 'react';
import AboutSvg from '../../images/avatar.svg'

const AboutImg = () => {
      return (
      <AboutSvg  />
    )
}

export default AboutImg;
